import React, { Fragment } from "react"
import Shaxmatka2Row from "./Shaxmatka2Row"
import HOME_TYPE from "shared/homeTypeList"
import { useTranslation } from "react-i18next"
import { ButtonBase, Grid } from "@mui/material"

const ShaxmatkaBlockItem = ({
	blockItem,
	blockIndex,
	toggleSelectionItem = () => {}
}) => {
	const { t } = useTranslation()
	const getRoomsByStatus = (statusCode) => {
		return blockItem?.homes.length > 0
			? blockItem?.homes.filter((item) => item.status === statusCode).length
			: 0
	}

	const getFullFloorsNumberByBlock = (block,padez) => {
		let a = Array.from(new Set(padez ? block.homes.filter(item => item.padez == padez).map((item) => item.stage) : block.homes.map((item) => item.stage) ))
		if (a.includes("-1")) {
			a.push("0")
		}
		a.sort((x, y) => x - y)
		return a.reverse()
	}
	const getFullPadezNumberByBlock = (block) => {
		let a = Array.from(new Set(block.homes.map((item) => item.padez)))
		if (a.includes("-1")) {
			a.push("0")
		}
		return a
	}
	return (
		<Fragment>
			<div className="my-2 ml-10 leading-4 mb-7">
				<span className="font-medium text-lg text-line-1">
					{blockItem?.name}
				</span>
				<span className="text-sm leading-4">
					({t("shaxmatka.homeType.free")}:{" "}
					{getRoomsByStatus(HOME_TYPE.ACTIVE.code)},{" "}
					{t("shaxmatka.homeType.ordered")}:{" "}
					{getRoomsByStatus(HOME_TYPE.TIME.code)},{" "}
					{t("shaxmatka.homeType.sold")}:{" "}
					{getRoomsByStatus(HOME_TYPE.ORDERED.code)})
				</span>
			</div>
			<div className="flex gap-[2px]">
			<div>
			{blockItem?.homes.includes("padez") && (
				<div > </div>
			)}
			{getFullFloorsNumberByBlock(blockItem).map((floorNumber, index) => (
					<div
						className={`sheet-row !px-0 !bg-transparent  floor-${floorNumber}`}
						key={`block-${blockItem?.id}-row-${index}`}
						id={`block-${blockItem?.id}-row-${index}`}
					>
						<Grid
				container
				rowSpacing={0.5}
				columns={{
					xs: parseInt(new Set(blockItem?.homes.map((item) => item.stage)).size),
					sm: parseInt(new Set(blockItem?.homes.map((item) => item.stage)).size)
				}}
				className="sheet-home-row  !max-w-3"
			>
						<Grid
						item={true}
						sm={1}
						xs={1}
						className={`sheet-home-cell !max-w-0 floor-${floorNumber}`}
						>
							<ButtonBase
										className={`block-${blockIndex}-home !cursor-default !bg-transparent !max-w-0 !px-0 !shadow-none home-item`}
									>
							{floorNumber}
							</ButtonBase>
						</Grid>
						</Grid>
					</div>
				))}
			</div>
			{getFullPadezNumberByBlock(blockItem).map((padez)=>(
				<div className={`${padez > 1 ? "ml-3": ""}`}>
					{padez ? <div className="pl-2">{t("padyezd.padez",{indexPadez: padez})}</div> : ""}
					{getFullFloorsNumberByBlock(blockItem,padez).map((floorNumber, index) => (
					<div
						className={`sheet-row !px-1 floor-${floorNumber}`}
						key={`block-${blockItem?.id}-row-${index}`}
						id={`block-${blockItem?.id}-row-${index}`}
					>
						<Shaxmatka2Row
							homesData={blockItem?.homes}
							blockIndex={blockIndex}
							padezIndex={padez}
							floorNumber={floorNumber}
							size={new Set(blockItem?.homes.map((item) => item.stage)).size}
							toggleSelectionItem={toggleSelectionItem}
						/>
					</div>
				))}</div>
			))}
			</div>
			{/* <div>
			{getFullFloorsNumberByBlock(blockItem).map((floorNumber, index) => (
				<div
					className={`sheet-row floor-${floorNumber}`}
					key={`block-${blockItem?.id}-row-${index}`}
					id={`block-${blockItem?.id}-row-${index}`}
				>
					<Shaxmatka2Row
						homesData={blockItem?.homes}
						blockIndex={blockIndex}
						floorNumber={floorNumber}
						size={new Set(blockItem?.homes.map((item) => item.stage)).size}
						toggleSelectionItem={toggleSelectionItem}
					/>
				</div>
			))}
			</div> */}
			<div className="mt-2 ml-10 leading-4">
				<span className="font-medium text-lg text-line-1">
					{blockItem?.name}
				</span>
				<span className="text-sm leading-4">
					({t("shaxmatka.homeType.free")}:{" "}
					{getRoomsByStatus(HOME_TYPE.ACTIVE.code)},{" "}
					{t("shaxmatka.homeType.ordered")}:{" "}
					{getRoomsByStatus(HOME_TYPE.TIME.code)},{" "}
					{t("shaxmatka.homeType.sold")}:{" "}
					{getRoomsByStatus(HOME_TYPE.ORDERED.code)})
				</span>
			</div>
		</Fragment>
	)
}

export default ShaxmatkaBlockItem
